<template>
    <div class="totalBox">
        <jy-query ref="checkForm" :model="formInline" label-width="72px">
            <jy-query-item label="机构:" prop="orgName">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="formInline.orgName"></el-input>
                </div>
            </jy-query-item>
            <!-- <jy-query-item label="车牌号:" prop="vehicleNo">
                <el-input v-model="formInline.vehicleNo" placeholder="请输入"></el-input>
            </jy-query-item> -->
            <jy-query-item label="司机:" prop="driverName">
                <el-input v-model="formInline.driverName" placeholder="请输入"></el-input>
            </jy-query-item>
            <jy-query-item label="查询日期:" prop="wztime" :span="1.5">
                <el-date-picker v-model="formInline.wztime" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :unlink-panels="true" :picker-options="pickerOptions"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('list')">查询</el-button>
                <el-button type="primary" @click="resetForm('checkForm')" v-if="btnexist('reset')">重置</el-button>
            </template>
        </jy-query>
        <el-table :data="dataList" :summary-method="getSummaries" show-summary>
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column prop="routeName" label="所属线路" min-width="120"></jy-table-column>
            <jy-table-column prop="orgName" label="所属机构" min-width="120"></jy-table-column>
            <jy-table-column prop="driverName" label="司机" min-width="120"></jy-table-column>
            <jy-table-column prop="jobNum" label="员工工号" min-width="120"></jy-table-column>
            <jy-table-column prop="planShift" label="计划班次" min-width="120"></jy-table-column>
            <jy-table-column prop="actualShfit" label="实际班次" min-width="120"></jy-table-column>
            <jy-table-column prop="completeShift" label="完成班次" min-width="120"></jy-table-column>
            <jy-table-column prop="incompleteShift" label="未完成班次" min-width="120"></jy-table-column>
            <jy-table-column prop="rentShift" label="定点专线班次" min-width="120"></jy-table-column>
            <jy-table-column prop="operatingMil" label="营运里程（km）" min-width="120"></jy-table-column>
            <jy-table-column prop="rentShiftMil" label="定点专线里程（km）" min-width="120"></jy-table-column>
            <jy-table-column prop="nonOperatingMil" label="非营运里程（km）" min-width="120"></jy-table-column>
            <jy-table-column prop="totalMil" label="总里程（km）" min-width="120"></jy-table-column>
            <jy-table-column prop="cutShift" label="砍班班次" min-width="120"></jy-table-column>
            <jy-table-column prop="cutShiftRate" label="砍班率（%）" min-width="120"></jy-table-column>
            <jy-table-column prop="outShift" label="脱班班次" min-width="120"></jy-table-column>
            <jy-table-column prop="outShiftRate" label="脱班率（%）" min-width="120"></jy-table-column>
            <jy-table-column prop="depOnTimeNum" label="准点发班数" min-width="120"></jy-table-column>
            <jy-table-column prop="depOnTimeRate" label="发车准点率（%）" min-width="120"></jy-table-column>
        </el-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" url="/system/org/queryOrgTree" title="选择机构" selectType="3" typeName="orgType"></institutions-tree>
    </div>
</template>
<script>
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                },
            },
            formInline: {
                orgName: '',
                orgId: '',
                // vehicleNo: '',
                driverName: '',
                wztime: '',
                beginTime: '',
                endTime: '',
            },
            pageSize: 10,
            total: 100,
            pageIndex: 1,
            dataList: [],

            btnRoute:'/admin/statisticalAnalysis/runProduction/runProductionDriverTotal',
        }
    },
    filters: {},
    watch: {
        'formInline.wztime'(val) {
            console.log(val)
            if (val.length != 1) {
                this.formInline.beginTime = val[0]
                this.formInline.endTime = val[1]
            } else {
                this.formInline.beginTime = ''
                this.formInline.endTime = ''
            }
        },
    },
    mixins:[btnMixins],
    components: {
        institutionsTree,
    },

    computed: {},

    activated() {
        this.getList()
    },

    created() {
        // this.getList()
    },

    methods: {
        getList() {
            let url = '/statoperatingdaily/queryPageListByDriver'
            let option = {
                ...this.formInline,
                pageSize: this.pageSize,
                pageIndex: this.pageIndex
            }
            this.$http.post(url, option).then(({ detail }) => {
                this.dataList = detail.list
                this.total = detail.total
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        // 选择机构树
        selectInstitutions() {
            this.$refs.institutions.init()
        },
        // 上级机构查询
        addInstitutions(data) {
            // console.log(data)
            this.formInline.orgId = data.orgId
            this.formInline.orgName = data.name
        },
        getSummaries(param){
            const { columns, data } = param
			const sums = [];
            if(data&&data.length){
                columns.forEach((column, index) => {
                    let type = Object.prototype.toString.call(data[0][column.property])
                    console.log(type.slice(8,-1))
                    if(index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    if(type && type.slice(8,-1)=='Number'){
                        let total = 0
                        data.forEach(item=>{
                            total = total+item[column.property]
                        })
                        sums[index] = total.toFixed(2)
                    }else{
                        sums[index] =''
                    }
                })
            }
            return sums
        },
    }
}

</script>
<style lang="scss">
    .totalBox {
      .el-table{
        overflow-x: auto;
      }
      .el-table__body-wrapper,
      .el-table__header-wrapper,
      .el-table__footer-wrapper{
        overflow:visible;
      }
      .el-table::after{
        position: relative !important;
      }
    }
</style>
